module.exports = {
  messages: {
    BUTTON_SHOW_MORE: 'Show more',
    JOBS_MATCHING_REASONS_ERROR:
      'The AI couldn’t load the key matches between your search and the job description.',
    JOBS_MATCHING_REASONS_LOADING: 'Looking for key matches in the description',
    JOBS_MATCHING_REASONS_TIP_HEADLINE: 'See why a job matches your search',
    JOBS_MATCHING_REASONS_TIP_SUBTITLE: 'Beta AI feature for you',
    JOBS_SALARY_XING_ESTIMATED: '(XING estimate)',
    JOBS_TIMESTAMP_30_PLUS_DAYS_AGO: '30+ days ago',
    JOBS_TIMESTAMP_JUST_NOW: 'Just now',
    JOBS_TIMESTAMP_N_DAYS_AGO: '{number} days ago',
    JOBS_TIMESTAMP_N_HOURS_AGO:
      '{number, plural, =1 {1 hour ago} other {# hours ago}}',
    JOBS_TIMESTAMP_YESTERDAY: 'Yesterday',
  },
};
